import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>Security Engineering on AWS へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/security-engineering-on-aws/" target="blank">Security Engineering on AWS について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
        <h5>受講準備</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・ブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・本コースではマイクの使用は必須ではありません。スピーカー（やヘッドホンなど）を準備いただければ OK です。<br/>
        ・可能であればモニターは複数用意していただくと演習（ラボ）を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・音声が聞こえない方は P. 28 をご確認ください<br/>
        ===== <a href="https://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">【2】受講者ガイド利用手順</a> =====<br/>
        ・コース開始前に <a href="https://evantage.gilmoreglobal.com/" target="blank">こちら</a> からアカウントの作成をお願いします。<br/>
        ・講義で用いる教材は「Student Guide」、演習の手順書は「Lab Guide」となります。<br/>
        ・ただし、スライドの並び替えや取捨選択、補足資料の追加などをしておりますので、教材ではなく Webex の画面を見ながらの受講をお願いします。<br/>
        ・トレーニングの期間中に必ず 1 度は教材への閲覧をお願いします。<br/>
        ・教材は、引き換え後、720 日間閲覧可能です。<br/>
        ※ 稀に、引き換え後にガイドが表示されないことがあります。同期の問題であることが多いので、午後に [更新] ボタンを押してみてください。<br/>
        ====== 【3】受講者ポータルへのアクセス方法 =====<br/>
        ・ログインページは <a href="" target="blank">こちら(XXXXXXXX - XXXXXXXX)</a> です。<br/>
        ・受講者ポータルから、ラボ（ハンズオン）の実施や、教科書の閲覧ができます。<br/>
        ・研修を申し込む際に使用したアカウントでログインをお願いします。<br/>
        ・受講者ポータルでのラボ実施は、トレーニング中の3日間のみアクセス可能です。ご留意下さいませ。<br/>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          <a href="https://seceng-appendix-x.s3.ap-northeast-1.amazonaws.com/SecurityEngineeringOnAWS_Module_Appendix.pdf" target="blank">トレーニング付加資料</a>
        </p>
        
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>1</td>
            <td>AWS のセキュリティ</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>AWS のエントリポイントを確認する</td>
            <td><span class="point_box"><b>ラボ1</b></span>アイデンティティ＆リソースベースポリシーの利用</td>
          </tr>
          <tr>
            <td>3</td>
            <td>セキュリティに関する考慮事項: ウェブアプリケーション</td>
            <td></td>
          </tr>
          <tr>
            <td>4</td>
            <td>アプリケーションのセキュリティ</td>
            <td><span class="point_box"><b>ラボ2</b></span>AWS Systems Manager と Amazon Inspector を使用する</td>
          </tr>
          <tr>
            <td>5</td>
            <td>データセキュリティ</td>
            <td></td>
          </tr>
        </table>
        <hr/>
        <h5>Day 2</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>6</td>
            <td>ネットワーク通信を保護する</td>
            <td></td>
          </tr>
          <tr>
            <td>7</td>
            <td>AWS でログをモニタリング、収集する</td>
            <td><span class="point_box"><b>ラボ3</b></span>AWS Config を使用したモニタリングと応答</td>
          </tr>
          <tr>
            <td>8</td>
            <td>AWS でログを処理する</td>
            <td><span class="point_box"><b>ラボ4</b></span>ウェブサーバーのログを分析する</td>
          </tr>
          <tr>
            <td>9</td>
            <td>セキュリティに関する考慮事項: ハイブリッド環境</td>
            <td></td>
          </tr>
          <tr>
            <td>10</td>
            <td>リージョン外の保護</td>
            <td></td>
          </tr>
        </table>
        <hr/>
        <h5>Day 3</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>11</td>
            <td>セキュリティに関する考慮事項: サーバーレス環境</td>
            <td></td>
          </tr>
          <tr>
            <td>12</td>
            <td>脅威の検出と調査</td>
            <td></td>
          </tr>
          <tr>
            <td>13</td>
            <td>AWS での機密情報管理</td>
            <td><span class="point_box"><b>ラボ5</b></span>AWS KMS を使用する</td>
          </tr>
          <tr>
            <td>14</td>
            <td>設計による自動化とセキュリティ</td>
            <td><span class="point_box"><b>ラボ6</b></span>AWS Service Catalog を使用する（オプション）</td>
          </tr>
          <tr>
            <td>15</td>
            <td>AWS でのアカウント管理とプロビジョニング</td>
            <td><span class="point_box"><b>ラボ7</b></span>AD FS を使用した AWS フェデレーション認証（オプション）</td>
          </tr>
          <tr>
            <td>エンディング</td>
            <td>コースのまとめ＆アンケート入力</td>
            <td></td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール 1（AWS のセキュリティ）</h5>
        ・<a href="https://www.idnet.co.jp/column/page_004.html" target="blank">【エバンジェリスト・ボイス】CIA？AIC？情報セキュリティの3要素とインターネットの可用性＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/whitepapers/latest/aws-security-incident-response-guide/welcome.html" target="blank">AWS Security Incident Response Guide　＊英語のみ</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/classify-the-emails-notification-delivered-from-aws/" target="blank">AWS から配信されるメールについて運用チーム視点でまとめてみた＜外部サイト＞</a><br/>
        ・<a href="http://dmiyakawa.github.io/blog/2014/05/02/your-aws-account-is-compromisednau/" target="blank">Your AWS Account Is Compromisedなう＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/forensic-investigation-environment-strategies-in-the-aws-cloud/" target="blank">AWS 上でフォレンジック調査環境を構築する際の方策</a><br/>
        ・<a href="https://aws.amazon.com/jp/security/penetration-testing/" target="blank">侵入テスト</a><br/>
        <hr/>
        <h5>モジュール 2（AWS のエントリポイントを確認する）</h5>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202210/way-to-operate-api-2/" target="blank"> AWS の API を理解しよう !　中級編 ~ リクエストの署名や CLI/SDK の中身を覗いてみる</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/happy-10th-birthday-aws-identity-and-access-management/" target="blank">10歳の誕生日おめでとう – AWS Identity and Access Management</a><br/>
        ・<a href="https://www.youtube.com/watch?v=8_Xs8Ik0h1w&list=PL2yQDdvlhXf9jfiZENJYPXX8GYUOzQCuT&t=3040s" target="blank">AWS re:Invent 2021 - Keynote with Dr. Werner Vogels</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS の 200 を超えるサービスと連携する AWS IAM は秒間 5 億の API コールを処理しているというお話です。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/id_root-user.html" target="blank">AWS アカウントのルートユーザー</a><br/>
        ・<a href="https://aws.amazon.com/jp/register-flow/" target="blank">AWS アカウント作成の流れ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/best-practices.html" target="blank">IAM でのセキュリティのベストプラクティス</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies_evaluation-logic.html" target="blank">ポリシーの評価論理</a><br/>
        ・<a href="https://awspolicygen.s3.amazonaws.com/policygen.html" target="blank">AWS Policy Generator</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202303/learn-iam-role/" target="blank"> テクニカルトレーナーと学ぶ AWS IAM ロール ~ ここが知りたかった ! つまずきやすい部分を理解してモヤっとを解消</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cli/latest/userguide/cli-configure-quickstart.html#cli-configure-quickstart-precedence" target="blank">構成設定と優先順位</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS CLI 認証情報と構成設定に関しての優先順位順です。なぜか、IAM で Access Denied になってしまう場合はこちらのドキュメントを参照すると良いかもしれません。<br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/knowledge-center/s3-access-denied-listobjects-sync/" target="blank">Amazon S3 バケットで sync コマンドを実行すると、ListObjectsV2 で Access Denied (アクセス拒否) エラーが表示されるのはなぜですか?</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies.html" target="blank">IAM JSON ポリシーリファレンス</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2017/summit/slide/D2T4-6.pdf" target="blank">KDDI流 クラウド・セキュリティ　〜「⼤企業のクラウド適応」 秘伝のレシピ〜</a><br/>
        ・<a href="https://www.youtube.com/watch?v=oUWnKlRJklE" target="blank">KDDI 流 クラウド・セキュリティ ～「大企業のクラウド適応」 秘伝のレシピ～｜AWS Summit Tokyo 2017</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/solution-casestudy/20160621_AWS-BlackBelt-Authority-public.pdf" target="blank">AWS Black Belt Online Seminar AWSサービスの権限管理</a><br/>
        ・<a href="https://iselegant.hatenablog.com/entry/2020/05/24/215808" target="blank">AWSマルチアカウントにおけるIAMユーザー設計戦略を考えてみる＜外部サイト＞</a><br/>
        ・<a href="https://www.amazon.co.jp/AWS%E3%81%AE%E8%96%84%E3%81%84%E6%9C%AC-IAM%E3%81%AE%E3%83%9E%E3%83%8B%E3%82%A2%E3%83%83%E3%82%AF%E3%81%AA%E8%A9%B1-%E4%BD%90%E3%80%85%E6%9C%A8%E6%8B%93%E9%83%8E-ebook/dp/B085PZCMG2" target="blank">AWSの薄い本　IAMのマニアックな話 Kindle版</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/access_policies_boundaries.html" target="blank">IAM エンティティのアクセス許可境界</a><br/>
        ・<a href="https://speakerdeck.com/dena_tech/techcon2021-19" target="blank">AWS IAMの属人的な管理からの脱却【DeNA TechCon 2021】/techcon2021-19＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/iam/features/mfa/" target="blank">Multi-Factor Authentication (MFA) for IAM　＊英語のみ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/id_credentials_mfa_lost-or-broken.html" target="blank">MFA デバイスの紛失および故障時の対応</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/awscloudtrail/latest/userguide/query-lake-examples.html" target="blank">クエリの例</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/extend-aws-iam-roles-to-workloads-outside-of-aws-with-iam-roles-anywhere/" target="blank">IAM Roles Anywhere で AWS IAM ロールを AWS 外部のワークロードに拡張する</a><br/>
        <hr/>
        <h5>モジュール 3（セキュリティに関する考慮事項: ウェブアプリケーション）</h5>
        ・<a href="https://engineering.mercari.com/blog/entry/20220426-threat-modeling-at-mercari/" target="blank">メルカリの脅威モデリングプロセス＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/awssupport/latest/user/trusted-advisor-check-reference.html" target="blank">「AWS Trusted Advisor ベストプラクティスチェックリスト」</a><br/>
        <hr/>
        <h5>モジュール 4（アプリケーションのセキュリティ）</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200825_BlackBelt_EC2imagebuilder.pdf" target="blank">[AWS Black Belt Online Seminar] EC2 Image Builder</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/improved-automated-vulnerability-management-for-cloud-workloads-with-a-new-amazon-inspector/" target="blank">新しい Amazon Inspector – クラウドワークロード向けの脆弱性管理が改善および自動化</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2022/09/amazon-inspector-windows-os-continual-software-vulnerability-scanning-ec2-workloads/" target="blank">Amazon Inspector が Windows オペレーティングシステム (OS) で EC2 ワークロードにおけるソフトウェア脆弱性の継続スキャンのサポートを開始</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/amazon-inspector-now-scans-aws-lambda-functions-for-vulnerabilities/" target="blank">Amazon Inspector が AWS Lambda 関数をスキャンして脆弱性を検出するようになりました</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2023/04/amazon-inspector-deep-inspection-ec2-instances/" target="blank">Amazon Inspector で EC2 インスタンスのディープインスペクションのサポートを開始</a><br/>
        ・<a href="https://aws.amazon.com/jp/inspector/faqs/" target="blank">Amazon Inspector のよくある質問</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/automate-vulnerability-management-and-remediation-in-aws-using-amazon-inspector-and-aws-systems-manager-part-1/" target="blank">Amazon Inspector と AWS Systems Manager を用いた脆弱性管理と修復の自動化 – Part1</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/automate-vulnerability-management-and-remediation-in-aws-using-amazon-inspector-and-aws-systems-manager-part-2/" target="blank">Amazon Inspector と AWS Systems Manager を用いた脆弱性管理と修復の自動化 – Part2</a><br/>
        <hr/>
        <h5>モジュール 5（データセキュリティ）</h5>
        ・<a href="https://aws.amazon.com/jp/blogs/news/introducing-s3-security-best-practices-1/" target="blank">Amazon S3 セキュリティベストプラクティスの実践（権限管理のポリシー) – 前編</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2023/01/amazon-s3-automatically-encrypts-new-objects/" target="blank">Amazon S3 ですべての新しいオブジェクトが自動的に暗号化</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonS3/latest/userguide/default-bucket-encryption.html" target="blank">Amazon S3 のデフォルトバケット暗号化の有効化</a><br/>
        ・<a href="https://www.javadrive.jp/mysql/user/" target="blank">ユーザーの作成＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/data_disposal/" target="blank">クラウドにおける安全なデータの廃棄</a><br/>
        <hr/>
        <h5>モジュール 6（ネットワーク通信を保護する）</h5>
        ・<a href="https://dev.classmethod.jp/articles/how-to-capture-packets-outside-ec2-with-vpc-traffic-mirroring/" target="blank">【ハンズオン】VPCトラフィクミラーリングを使ってEC2にログインせずにパケットキャプチャする方法を学ぼう#AWSSummit＜外部サイト＞</a><br/>
        ・<a href="https://japan.zdnet.com/article/35057659/" target="blank">自前の変電所まで！--AWS幹部がデータセンターの詳細を紹介＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/vpc/faqs/#Connectivity" target="blank">「Amazon VPC のよくある質問」</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20181219_BlackBeltSeminar_AWS_Certificate_Manager.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Certificate Manager</a><br/>
        <hr/>
        <h5>モジュール 7（高可用性システムの構成）</h5>
        ・<a href="https://fdsa-life.jp/blog/?p=1629" target="blank">リスクの統制「予防と発見」を活用する。【監査技術の応用】＜外部サイト＞</a><br/>
        ・<a href="https://speakerdeck.com/track3jyo/startup-monitoring-aws2022" target="blank">~スタートアップの人たちに捧ぐ~ 監視再入門 in AWS</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- CloudWatch を活用したモニタリング構成例（スライド 20）がとても分かりやすい資料です。スタートアップならずともオススメの資料です。<br/>
        ・<a href="https://www.datadoghq.com/ja/blog/aws-monitoring/" target="blank">AWS 監視のための主要なメトリクス＜外部サイト＞</a><br/>
        ・<a href="https://tech.nri-net.com/entry/2021/05/26/102844" target="blank">IAMユーザーのアクセスキー作成を簡単に通知して敏感になる＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/config/latest/developerguide/managed-rules-by-aws-config.html" target="blank">AWS Config マネージドルールのリスト</a><br/>
        ・<a href="https://speakerdeck.com/nagahara/continuous-compliance-with-aws-config" target="blank">AWS Config による継続的コンプライアンス実現に向けた取り組み / Continuous Compliance With AWS Config＜外部サイト＞</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200812_AWS-BlackBelt-Macie.pdf#page=28" target="blank">[AWS Black Belt Online Seminar] Amazon Macie (P. 28)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 日本語対応させたい場合の Tips が記載されている資料です。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonCloudWatch/latest/logs/MonitoringPolicyExamples.html" target="blank">メトリクスフィルターの作成</a><br/>
        <hr/>
        <h5>モジュール 8（AWS でログを処理する）</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20180110_AWS-BlackBelt-Kinesis.pdf" target="blank">【AWS Black Belt Online Seminar】Amazon Kinesis</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2017/summit/slide/D3T1-2.pdf" target="blank">Speed matters - Amazon Kinesis が実現するストリーミングデータのリアルタイム分析</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/I_AWS_Media_Seminar_20210318PM_Session_2.pdf" target="blank">データレイクアーキテクチャと Purpose-build な分析サービス活用事例の紹介</a><br/>
        <hr/>
        <h5>モジュール 10（リージョン外の保護）</h5>
        ・<a href="https://d1.awsstatic.com/whitepapers/ja_JP/Security/DDoS_White_Paper.pdf" target="blank">AWS Best Practices for DDoS Resiliency</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 2018/12 の日本語資料です。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/whitepapers/latest/aws-best-practices-ddos-resiliency/aws-best-practices-ddos-resiliency.pdf" target="blank">AWS Best Practices for DDoS Resiliency AWS Whitepaper</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 2022/4 の英語資料です。<br/>
        ・<a href="https://www.slideshare.net/AmazonWebServices/under-the-hood-of-amazon-route-53-arc408r1-aws-reinvent-2018" target="blank">Under the Hood of Amazon Route 53 (ARC408-R1) - AWS re:Invent 2018</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/reduce-ddos-risks-using-amazon-route-53-and-aws-shield/" target="blank">Amazon Route 53 および AWS Shield を使用した DDoS リスクの軽減</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-library/workload-isolation-using-shuffle-sharding/" target="blank">シャッフルシャーディングを使ったワークロードの分離</a><br/>
        ・<a href="https://tech.smarthr.jp/entry/2020/11/24/150008" target="blank">SmartHRにおけるAWS WAF運用術＜外部サイト＞</a><br/>
        ・<a href="https://blog.serverworks.co.jp/2020/12/30/090000" target="blank">AWS WAFをどのように導入するか＜外部サイト＞</a><br/>
        ・<a href="https://www.ipa.go.jp/files/000017312.pdf" target="blank">Web Application Firewall (WAF) 読本　改訂第２版＜外部サイト＞</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS WAF に限らず WAF の一般的な概要／詳細／導入などに言及している資料です。当モジュールの内容と併せましてご参照頂けますと幸いです。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/vpc/latest/userguide/working-with-aws-managed-prefix-lists.html" target="blank">AWS マネージドプレフィックスリストの提供</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/limit-access-to-your-origins-using-the-aws-managed-prefix-list-for-amazon-cloudfront/" target="blank">Amazon CloudFront用のAWS マネージドプレフィックスリストを使用したオリジンへのアクセス制限</a><br/>
        ・<a href="https://aws.amazon.com/jp/shield/getting-started/" target="blank">AWS Shield の開始方法</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20170718_AWS-BlackBelt-Shield.pdf#page=19" target="blank">【AWS Black Belt Online Seminar】AWS Shield (P. 19)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Standard に関する細かな紹介はその他中々無いものの、当 Blackbelt 資料では紹介されています。貴重な例です。<br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2020/10/aws-shield-provides-global-and-per-account-event-summaries-to-all-aws-customers/" target="blank">AWS Shield がすべての AWS のお客様にグローバルおよびアカウントごとのイベントの概要を提供開始</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 2020/10 のアップデートによりすべての AWS のお客様にグローバルおよびアカウントごとのイベントの概要を提供開始しました。<br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/jp_shield_support/" target="blank">AWS Shield Advanced における日本語サポートの制約事項</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/20220915-web-security-event/" target="blank">【開催報告】最新のサイバー攻撃の現状とWebセキュリティ対策(WAF/DDoS対策)実例セミナー</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 2022/9 に開催されたセミナーの報告記事ではありますが、当モジュールと関連する内容が盛り込まれている為のご紹介です。ログの分析や実際に攻撃を受けた際の対応フロー整備などについても言及されています。<br/>
        <h5>モジュール 11（セキュリティに関する考慮事項: サーバーレス環境）</h5>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200630_AWS_BlackBelt_Amazon%20Cognito.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon Cognito</a><br/>
        ・<a href="https://jwt.io/" target="blank">JWT</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cognito/latest/developerguide/cognito-user-pool-settings-adaptive-authentication.html" target="blank">アダプティブ認証の使用</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20190813_AWS-BlackBelt_ServerlessSecurityPractice.pdf" target="blank">[AWS Black Belt Online Seminar] 実践的サーバーレスセキュリティプラクティス</a><br/>
        <hr/>
        <h5>モジュール 12（脅威の検出と調査）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/guardduty/latest/ug/guardduty_finding-types-active.html" target="blank">検出結果タイプ</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/webinar-bb-guardduty-2018/" target="blank">[AWS Black Belt Online Seminar] Amazon GuardDuty 資料及び QA 公開 (Q. 11 部分)</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/webinar-bb-amazon-detective-2020/" target="blank">[AWS Black Belt Online Seminar] Amazon Detective 資料及び QA 公開 (「複数リージョン」Q 部分)</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2022/01/amazon-guardduty-elastic-kubernetes-service-clusters/" target="blank">Amazon GuardDuty が Amazon Elastic Kubernetes Service クラスターの保護を開始</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2022/07/malware-protection-feature-amazon-guardduty/" target="blank">Amazon GuardDuty がマルウェア対策機能を追加</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/securityhub/latest/userguide/standards-available.html" target="blank">AWS Security Hub で使用可能なセキュリティ標準</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20201013_AWS-BlackBelt-AWSSecurityHub.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Security Hub</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2022/11/security-hub-center-internet-securitys-cis-foundations-benchmark-version-1-4-0/" target="blank">AWS Security Hub が Center for Internet Security (CIS) の AWS Foundations Benchmark バージョン 1.4.0 のサポートを開始</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200715_AWSBlackBelt2020_AmazonDetective.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon Detective</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/guardduty-detect-exfiltration-ec2-credentials-inside/" target="blank">[アップデート]GuardDutyが盗まれたEC2のクレデンシャルが別AWSアカウントで利用されたことを検知できるようになったので実際に試してついでにDetectiveで調査してみた＜外部サイト＞</a><br/>
        <hr/>
        <h5>モジュール 13（AWS での機密情報管理）</h5>
        ・<a href="https://dnsops.jp/event/20140627/DNS_Summer_Days_2014-HSM.pdf" target="blank">暗号鍵を保護するHSM＜外部サイト＞</a><br/>
        ・<a href="https://www.j-lis.go.jp/data/open/cnt/3/2035/1/security.pdf" target="blank">耐タンパー性の概要＜外部サイト＞</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20181121_AWS-BlackBelt-KMS.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Key Management Service</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202210/awsgeek-kms/" target="blank">データ保護に使う暗号化キーを一元管理。AWS KMS をグラレコで解説</a><br/>
        ・<a href="https://csrc.nist.gov/csrc/media/projects/cryptographic-module-validation-program/documents/security-policies/140sp3139.pdf" target="blank">（旧）FIPS 140-2 Non-Proprietary Security Policy＜外部サイト＞</a><br/>
        ・<a href="https://csrc.nist.gov/CSRC/media/projects/cryptographic-module-validation-program/documents/security-policies/140sp4523.pdf" target="blank">（新）FIPS 140-2 Non-Proprietary Security Policy＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cloudhsm/latest/userguide/fips-validation.html" target="blank">FIPS の検証</a><br/>
        ・<a href="https://warp.ndl.go.jp/info:ndljp/pid/12446699/www.ipa.go.jp/security/enc/smartcard/node60.html" target="blank">IV.1.2 セキュリティ要件＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cloudhsm/latest/userguide/third-party-applications.html" target="blank">サードパーティアプリケーションとAWS CloudHSMの連携</a><br/>
        <hr/>
        <h5>モジュール 14（設計による自動化とセキュリティ）</h5>
        ・<a href="https://www.sqat.jp/tamatebako/7205/" target="blank">前倒しで対処　ー セキュリティを考慮したソフトウェア開発アプローチ「シフトレフト」とはー＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/browse-all/" target="blank">AWS ソリューションライブラリ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AWSCloudFormation/latest/UserGuide/sample-templates-services-us-east-1.html#w2aac35c52c13c41" target="blank">Amazon Virtual Private Cloud (us-east-1)</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200826_AWS-BlackBelt_AWS-CloudFormation.pdf#page=82" target="blank">[AWS Black Belt Online Seminar] AWS CloudFormation (P.82)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- ライフサイクル別のテンプレート管理に関して分かりやすくまとめている資料となります。本編と合わせつつご参照下さい。<br/>
        <h5>モジュール 15（AWS でのアカウント管理とプロビジョニング）</h5>
        ・<a href="https://dev.classmethod.jp/articles/organizations-scp-inheritance/" target="blank">[AWS Organizations] SCP(サービスコントロールポリシー)の継承の仕組みを学ぼう＜外部サイト＞</a><br/>
        ・<a href="https://techblog.finatext.com/finatext-aws-guardrail-2705cd1e3679" target="blank">FinatextにおけるAWSのガードレール戦略の紹介＜外部サイト＞</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-23_AWS_Summit_Online_2021_MGT01.pdf" target="blank">AWS Management and Governance サービスによるイノベーションの加速</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_manage_policies_scps_examples_general.html" target="blank">一般的な例（SCP）</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies_evaluation-logic.html" target="blank">ポリシーの評価論理</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/controltower/latest/userguide/how-control-tower-works.html" target="blank">AWS Control Tower の仕組み</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20151021_aws-blackbelt-directory-service.pdf" target="blank">AWS Directory Service</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/202108_AWS_Black_Belt_Microsoft_AD.pdf" target="blank">AWS Managed Microsoft AD</a><br/>
        <hr/>
        <h5>その他（総合的に）</h5>
        ・<a href="https://d1.awsstatic.com/whitepapers/ja_JP/compliance/NIST_Cybersecurity_Framework_CSF.pdf" target="blank">NIST サイバーセキュリティフレームワーク (CSF)</a><br/>
        ・<a href="https://maturitymodel.security.aws.dev/en/model/" target="blank">AWS Security Maturity Model</a><br/>
        ・<a href="https://aws.amazon.com/security/security-bulletins/" target="blank">Security Bulletins</a><br/>
        ・<a href="https://aws-samples.github.io/jp-contents-hub/" target="blank">AWS 日本語ハンズオン</a><br/>
        ・<a href="https://catalog.us-east-1.prod.workshops.aws/workshops/60a6ee4e-e32d-42f5-bd9b-4a2f7c135a72/ja-JP" target="blank">SIEM on Amazon OpenSearch Service Workshop</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- ログの可視化の基盤構築を体験するワークショップです。（内容的にはモジュール8／ラボ4に関連する内容です）<br/>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから720日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a><br/>
        &nbsp;&nbsp;※👆の資料のコードによる引き換えは現在方式として（当コースの場合）運用が変更されております。前述の「受講者ポータル」より「受講者ガイド」および「ラボガイド」を引き換え下さい。（要 Bookshelf 登録）
        <hr/>
        <h5>Rocket Chat</h5>
        Q. チャットの内容はいつでも参照できますか？<br/>
        &nbsp;&nbsp;・いいえ、トレーニング最終日の翌日を目処に削除します。（トレーニング内で別途ご案内致します）
      </div>
    </main>
  );
};

export default MainContents;
